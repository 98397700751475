import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSubscription, getPremium, getPayments } from 'api/commerce'
import { getMe } from 'api/user'
import { getProviders } from 'api/auth'
import { useTranslation } from 'i18n'

import {
  withAuth,
  ManageAccount,
  Layout,
  Seo,
  EmailVerificationHandler,
  NotificationBar
} from 'components'
import { defaultGetStaticProps } from 'utilities/next'

function Home() {
  const dispatch = useDispatch()
  const { t } = useTranslation('account')

  useEffect(() => {
    dispatch(getMe())
    dispatch(getSubscription())
    dispatch(getPayments())
    dispatch(getPremium())
    dispatch(getProviders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title = t('accountTitle')
  const description = t('accountDescription')

  return (
    <>
      <Seo title={title} description={description} />
      <EmailVerificationHandler>
        <Layout>
          <NotificationBar />
          <ManageAccount />
        </Layout>
      </EmailVerificationHandler>
    </>
  )
}

export const getStaticProps = defaultGetStaticProps

export default withAuth(Home)
